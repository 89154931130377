import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPin.module.css';

const IconPin = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="-3 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-183.000000, -5439.000000)"
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M134,5287.635 C133.449,5287.635 133,5287.186 133,5286.635 C133,5286.084 133.449,5285.635 134,5285.635 C134.551,5285.635 135,5286.084 135,5286.635 C135,5287.186 134.551,5287.635 134,5287.635 M134,5283.635 C132.343,5283.635 131,5284.978 131,5286.635 C131,5288.292 132.343,5289.635 134,5289.635 C135.657,5289.635 137,5288.292 137,5286.635 C137,5284.978 135.657,5283.635 134,5283.635 M134,5296 C134,5296 129,5289 129,5286 C129,5283.243 131.243,5281 134,5281 C136.757,5281 139,5283.243 139,5286 C139,5289 134,5296 134,5296 M134,5279 C130.134,5279 127,5282.134 127,5286 C127,5289.866 134,5299 134,5299 C134,5299 141,5289.866 141,5286 C141,5282.134 137.866,5279 134,5279"
              id="pin_sharp_circle-[#625]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconPin.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPin.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPin;
