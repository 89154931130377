import React from 'react';
import classNames from 'classnames';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix, showThumbnails=true, showFullscreenButton, className } = props;
  const images = listing.images;
  const imageVariants = !showThumbnails  ? 
  [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`] : ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const classes = classNames(className || css.productGallery);
  return (
    <div className={classes} data-testid="carousel">
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        showThumbnails={showThumbnails}
        showFullscreenButton={showFullscreenButton}
      />
    </div>
  );
};

export default SectionGallery;
